import { ASSET_FRAGMENT } from './fragments.graphql';

import { createApolloClient } from "../apollo/apollo-client";
import { gql } from "@apollo/client";

export const GET_PRODUCT_DETAIL = gql`
    query GetProductDetail($slug: String!) {
        product(slug: $slug) {
            id
            name
            slug
            description
            customFields{
                manufacturer
                templateId
                seoPageTitle
                seoMetaDescription
            }
            facetValues{
                facet{
                    code
                    name
                }
                name
                customFields{
                    icon{
                        source
                    }
                }                
            }
               channels{
                customFields{
                  deliverablePlaces
                  deliverableZipCodes
                }
              }
            variants {
                stockLevel
                id
                name
                rentalPlans
                rentalPricingPlans{
                    plans{
                        tenure
                        tenureUnit
                        deposit
                        securityDepositForShortTermWithEMandate
                        securityDepositForShortTermWithUpfront
                        securityDepositForTryAndBuyWithEMandate
                        securityDepositForTryAndBuyWithUpfront
                        price
                        rent
                        rentWithTax
                        isShortTermPlan
                    }
                }
                shortTermPricingPlan{
                    plans{
                        tenure
                        tenureUnit
                        deposit
                        securityDepositForShortTermWithEMandate
                        securityDepositForShortTermWithUpfront
                        securityDepositForTryAndBuyWithEMandate
                        securityDepositForTryAndBuyWithUpfront
                        price
                        rent
                        rentWithTax
                        isShortTermPlan         
                     }
                   }
                hasShortTermRentalPlan
                assets {
                    ...Asset
                }
                options {
                    code
                    name
                }
                price
                priceWithTax
                sku
            }
            optionGroups{
                code
                name
                options{
                    code
                    name
                }
            }
            featuredAsset {
                ...Asset
            }
            assets {
                ...Asset
            }
            collections {
                id
                slug
                breadcrumbs {
                    id
                    name
                    slug
                }
            }
        }
    }
    ${ASSET_FRAGMENT}
`;

export const GET_CHANNEL_PINCODES = gql`
query getProductVariantChannelDetail($id:ID!){
    product(id:$id){
      variants{
        id
        channels{
          customFields{
            deliverableZipCodes
          }
        }
      }
    }
  }
  `;

export const GET_CART_TOTALS = gql`
    query GetCartTotals {
        activeOrder {
            id
            active
            totalQuantity
            totalWithTax
        __typename
        }
    }
`;



export const productDetailsService = {
    async getProductDetails(slug?: any) {
        const client = createApolloClient({ "product-slug": slug });
        const resCollectionList = await client.query({
            query: GET_PRODUCT_DETAIL,
            variables: {
                slug,
            },
        })
        if (resCollectionList && resCollectionList.data)
            return Promise.resolve(resCollectionList.data.product);
        else return Promise.resolve([])
    },

    async getChannelPincodes(id?: any) {
        const client = createApolloClient({ "product-id": id });
        const response = await client.query({
            query: GET_CHANNEL_PINCODES,
            variables: {
                id
            },
        })
        if (response && response.data)
            return Promise.resolve(response.data.product);
        else return Promise.resolve([])
    },  
}