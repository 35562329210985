import { ASSET_FRAGMENT } from "./fragments.graphql";
import { config } from "../config.service";
import { createApolloClient } from "../apollo/apollo-client";
import { gql } from "@apollo/client";

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($input: SearchInput!) {
    search(input: $input) {
      items {
        inStock
        productId
        productVariantId
        productVariantName
        productVariantAsset {
          preview
        }
        slug
        sku
        facetValueIds
        facetIds
        productName
        description
        priceWithTax {
          ... on PriceRange {
            min
            max
          }
        }
        productAsset {
          id
          preview
          focalPoint {
            x
            y
          }
        }
        isAvailableForShortTermRental
        minShortTermRent
        minShortTermRentWithTax
        minRentWithTax
        minRent
      }
      totalItems
      facetValues {
        count
        facetValue {
          id
          name
          facet {
            id
            name
          }
        }
      }
    }
  }
`;

export const SEARCH_PRODUCTS_FOR_SITEMAP = gql`
  query SearchProducts($input: SearchInput!) {
    search(input: $input) {
      items {
        productId
        productVariantId
        slug
      }
      totalItems
    }
  }
`;

export const GET_COLLECTION = gql`
  query GetCollection($id: ID, $slug: String) {
    collection(id: $id, slug: $slug) {
      id
      name
      slug
      description
      featuredAsset {
        ...Asset
      }
      customFields {
        seoPageTitle
        seoMetaDescription
      }
      breadcrumbs {
        id
        slug
        name
      }
      children {
        id
        slug
        featuredAsset {
          ...Asset
        }
        name
      }
    }
  }
  ${ASSET_FRAGMENT}
`;

export const productSearchService = {
  async searchProductByCategory(categoryId: string) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: SEARCH_PRODUCTS,
      variables: {
        input: {
          term: "",
          // "groupByProduct": true,
          collectionId: categoryId,
          facetValueIds: [],
          take: 3000,
          skip: 0,
        },
      },
    });

    if (response && response.data) {
      return response.data.search;
    }
  },
  async searchProduct(
    skip: number,
    take: number,
    term?: string,
    categoryId?: string,
    facetValue?: string[],
    pricesort?: "ASC" | "DESC" | "Relevance"
  ) {
    const client = createApolloClient();

    const variablesValue =
      pricesort == "Relevance"
        ? {
            input: {
              term: term,
              groupByProduct: config.isGroupSearchProduct,
              collectionId: categoryId,
              facetValueIds: facetValue,
              take: take,
              skip: skip,
            },
          }
        : {
            input: {
              term: term,
              groupByProduct: true,
              collectionId: categoryId,
              facetValueIds: facetValue,
              take: take,
              skip: skip,
              sort: {
                price: pricesort,
              },
            },
          };

    const response = await client.mutate({
      mutation: SEARCH_PRODUCTS,
      variables: variablesValue,
    });

    if (response && response.data) {
      return response.data.search;
    }
  },

  async searchProductForSitemap(
    skip: number,
    term?: string,
    categoryId?: string,
    facetValue?: string[],
    pricesort?: "ASC" | "DESC" | "Relevance"
  ) {
    const client = createApolloClient();

    const variablesValue =
      pricesort == "Relevance"
        ? {
            input: {
              term: term,
              // "groupByProduct": true,
              collectionId: categoryId,
              facetValueIds: facetValue,
              take: 100,
              skip: skip,
            },
          }
        : {
            input: {
              term: term,
              // "groupByProduct": true,
              collectionId: categoryId,
              facetValueIds: facetValue,
              take: 100,
              skip: skip,
              sort: {
                price: pricesort,
              },
            },
          };

    const response = await client.mutate({
      mutation: SEARCH_PRODUCTS_FOR_SITEMAP,
      variables: variablesValue,
    });

    if (response && response.data) {
      return response.data.search;
    }
  },

  async getCategoryDetails(category: string) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: GET_COLLECTION,
      variables: {
        slug: category,
      },
    });

    if (response && response.data) {
      return response.data.collection;
    }
  },
};
