export class Analytics {
    constructor() {
    }
    event(ev: GoogleAnalyticsDefaultEventTypes, data: any) {
        if (typeof (window) === 'undefined') {
            return;
        }
        if ((window as any).gtag_gtag) {
            (window as any).gtag_gtag(ev, data);
        }
    }
    analyticsEvent(ev: GoogleAnalyticsDefaultEventTypes, data: any) {
        if (typeof (window) === 'undefined') {
            return;
        }
        if ((window as any).gtag) {
            (window as any).gtag('event', ev, data);
        }
    }

    sendAddToCartEvent(productDetails: any) {
        try {

            let item = this.prepareAddCartItem(productDetails, 0)
            let eventData = { currency: "INR", value: (item?.price * item?.tenure * item?.quantity).toFixed(2) ?? 0, items: [item] }
            this.event('add_to_cart', eventData);

        } catch (error) {
            console.log(error)
        }
    }

    sendViewCartEvent(cart: any) {
        try {

            let items = this.prepareItems(cart.lines)
            let totalSum = items.reduce((sum: any, item: any) => sum + parseFloat(item?.total ?? 0), 0);
            let eventData = { currency: "INR", value: totalSum, items: items }
            this.event('view_cart', eventData);

        } catch (error) {
            console.log(error)
        }

    }

    sendBeginCheckoutEvent(cart: any) {
        try {

            let items = this.prepareItems(cart.lines)
            let totalSum = items.reduce((sum: any, item: any) => sum + parseFloat(item?.total ?? 0), 0);
            let eventData = { currency: "INR", value: totalSum, source: cart.source, items: items }
            this.event('begin_checkout', eventData);

        } catch (error) {
            console.log(error)
        }

    }

    sendPurchaseEvent(cart: any) {
        try {
            let items = this.prepareItems(cart.lines)
            let totalSum = items.reduce((sum: any, item: any) => sum + parseFloat(item?.total ?? 0), 0);
            let eventData = { orderCode: cart.code, value: totalSum, source: cart.source, currency: "INR", items: items }
            this.event('purchase', eventData);
        } catch (error) {
            console.log(error)
        }

    }

    sendViewItemListEvent(productDetails: any) {
        try {
            let items = this.prepareViewListItems(productDetails.products)
            let eventData = { item_list_id: "search_products", item_list_name: "Search Produts", searchTerm: productDetails.searchTerm, facet: productDetails.facet, sort: productDetails.sort, items: items }
            this.event('view_item_list', eventData);
        } catch (error) {
            console.log(error)
        }
    }
    sendCategoryItemListEvent(productDetails: any) {
        try {
            let items = this.prepareViewListItems(productDetails.products)
            let eventData = { item_list_id: "category_products", item_list_name: "Category Produts", category: productDetails.searchTerm, facet: productDetails.facet, sort: productDetails.sort, items: items }
            this.event('view_item_list', eventData);
        } catch (error) {
            console.log(error)
        }
    }
    sendViewItemEvent(productDetails: any) {
        try {
            let item = this.prepareViewItem(productDetails)
            let eventData = { currency: "INR", value: (item?.price * item?.quantity * item?.tenure).toFixed(2) ?? 0, items: [item] }
            this.event('view_item', eventData);
        } catch (error) {
            console.log(error)
        }
    }

    sendDeleteFromCart(product: any) {
        try {
            let item = this.prepareCartItem(product, 0)
            let eventData = {
                currency: "INR", value: ((Number(item?.price) || 0) * (Number(item?.tenure) || 0) * (Number(item?.quantity) || 0)
                ).toFixed(2), items: [item]
            }
            this.event('remove_from_cart', eventData);

        } catch (error) {
            console.log(error)
        }
    }
    sendUserExperinceSelection(userWelcomeSelection: any) {
        try {
            // let item = this.prepareCartItem(product, 0)
            let eventData = { userWelcomeSelection: userWelcomeSelection }
            this.analyticsEvent('user_welcome_selection', eventData);

        } catch (error) {
            console.log(error)
        }
    }
    prepareItems(products: any) {
        const items = products?.map((product: any, index: any) => {
            return this.prepareCartItem(product, index)
        });
        return items;
    }

    prepareViewListItems(products: any) {
        const items = products?.map((product: any, index: any) => {
            let item = {
                item_id: product.sku,
                item_name: product.productName,
                index: index,
                item_variant: product.productVariantName,
                price: (product.minRent / 100).toFixed(2),
            };
            return item;
        });
        return items;
    }

    prepareCartItem(product: any, index: any) {

        let item = {
            item_id: product.productVariant.sku,
            item_name: product.productVariant.product.name,
            index: index,
            item_brand: this.getBrand(product.productVariant.product.facetValues),
            item_variant: product.productVariant.name,
            price: (product.customFields.rentPerMonthWithTax / 100).toFixed(2),
            quantity: product.quantity,
            tenure: product.customFields.rentalTenureInMonths,
            total: ((product.customFields.rentPerMonthWithTax * product.quantity * product.customFields.rentalTenureInMonths) / 100).toFixed(2),
        }
        let categories = this.getCategories(product.productVariant.product.facetValues);
        item = { ...item, ...categories }

        return item;
    }

    prepareAddCartItem(productDetails: any, index: any) {

        let item = {
            item_id: productDetails.product.variants[0].sku,
            item_name: productDetails.product.name,
            index: index + 1,
            item_brand: this.getBrand(productDetails.product.facetValues),
            item_variant: productDetails.product.variants[0].name,
            price: productDetails.price,
            quantity: productDetails.qty,
            tenure: productDetails.rentalTenureInMonths
        }
        let categories = this.getCategories(productDetails.product.facetValues);
        item = { ...item, ...categories }

        return item;
    }

    prepareViewItem(productDetails: any) {

        let item = {
            item_id: productDetails.variant.sku,
            item_name: productDetails.product.name,
            index: 0,
            item_brand: this.getBrand(productDetails.product.facetValues),
            item_variant: productDetails.variant.name,
            price: productDetails.price,
            quantity: 1,
            tenure: 12
        }
        let categories = this.getCategories(productDetails.product.facetValues);
        item = { ...item, ...categories }

        return item;
    }

    getBrand(facetValues: any) {
        const brandFacet = facetValues.reduce((result: any, facetValue: any) => {
            if (facetValue.facet.code === "brand") {
                return facetValue.name;
            }
            return result;
        }, '');

        return brandFacet;
    }

    getCategories(facetValues: any) {
        let categoryIndex = 0;
        const item = facetValues.reduce((result: any, facetValue: any) => {
            if (facetValue.facet.code === "category") {
                const key = "item_category" + (categoryIndex === 0 ? "" : categoryIndex + 1);
                result[key] = facetValue.name;
                categoryIndex++;
            }
            return result;
        }, {});
        return item;
    }
}

export const analytics = new Analytics();

export type GoogleAnalyticsDefaultEventTypes =
    'add_payment_info' |
    'add_to_cart' |
    'add_to_wishlist' |
    'begin_checkout' |
    'checkout_progress' |
    'generate_lead' |
    'login' |
    'purchase' |
    'refund' |
    'remove_from_cart' |
    'search' |
    'select_content' |
    'set_checkout_option' |
    'share' |
    'sign_up' |
    'view_item' |
    'view_item_list' |
    'view_promotion' |
    'view_search_results' |
    'view_cart' |

    // custom events
    'payment_failed' |
    'user_welcome_selection';