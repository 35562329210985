import * as React from "react";

import {
  AspectRatio,
  Box,
  Image,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Router, { useRouter } from "next/router";

import Link from "next/link";
import PriceTags from "./PriceTags";
import StaticUtils from "../../services/static-utils.service";

//pass props like price,onSale,name,imgSrc
export const ProductCard = (props: any) => {
  const imgAlt =
    props.name.length > 30 ? props.name.substring(0, 30) + "..." : props.name;
  return (
    <Link href={"/product/" + props.id} aria-label={imgAlt}>
      <Box
        maxW="7xl"
        mx="auto"
        // onClick={() => handleNav(props.id)}
        cursor="pointer"
      >
        <Stack spacing={{ base: "3", md: "4" }} m={{ base: "0 5px", md: "0" }}>
          <Box position="relative" borderRadius="6px">
            <AspectRatio ratio={3 / 3}>
              <Image
                src={props.imgSrc}
                alt={imgAlt}
                draggable="false"
                fallback={<Skeleton />}
                borderRadius={useBreakpointValue({ base: "md", md: "xl" })}
                objectFit="contain"
                style={{ objectFit: "contain" }}
              />
            </AspectRatio>
          </Box>
          <Stack h="76px">
            <Text
              fontWeight="700"
              fontSize="14px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {props.name}
            </Text>
            <PriceTags
              price={StaticUtils.formatAmount(props.price)}
              discountPrice={props.onSale}
            />
          </Stack>
        </Stack>
      </Box>
    </Link>
  );
};

function handleNav(id: any): void {
  Router.push("/product/" + id);
}
