/* eslint-disable react-hooks/rules-of-hooks */
import { Flex, HStack, Icon, Text } from "@chakra-ui/react"
import { GrDeliver } from "react-icons/gr"
import React from "react"

const PriceTags = (props: any) => {
  const { price, discountPrice, rootProps } = props

  return (
    <HStack spacing="6" {...rootProps}>
      {discountPrice ? (
        <>
          <Text fontWeight="semibold" size='14px'>₹ {discountPrice}/mo </Text>
          <Text as="span" fontWeight="medium" color='gray.400' textDecoration='line-through'>₹ {price}/mo </Text>
        </>
      ) : (
        <>
          <Flex mt="-1" flexDir={{ base: "row", sm: "column", md: "column", lg: "column", xl: "row" }} alignItems={{ base: "center", sm: "flex-start", lg: "center" }}>
            <Text as="span" fontWeight="500" color="black" opacity="0.5" fontSize={{ base: "12px", lg: '14px' }} mr="2" mb={{ md: "7px" }}>from </Text>
            <Text as="span" fontWeight="700" fontSize={{ base: "12px", lg: '14px' }} w={{ base: "105px", md: "90px", lg: "110px", xl: "115px" }} mb={{ md: "7px" }}>₹ {price}/mo </Text>
            {/* <Flex flexDir={{ base: "row", md: "row", lg: "row" }} ml={{ xl: "-30px" }} alignItems="center">
              <Icon as={GrDeliver} ml={{ base: "150px", md: "0", lg: "0", xl: "0" }} />
              <Text w={{ base: "100px", md: "50px" }} mt={{ base: "-3px", md: "-3px", lg: "-3px" }} ml={{ base: "2px", md: "2px", lg: "2px" }}>3 days</Text>
            </Flex> */}
          </Flex>
        </>)}
    </HStack>)
}
export default PriceTags