import { gql } from '@apollo/client';
import { createApolloClient } from '../apollo/apollo-client';
import { CART_FRAGMENT } from './fragments.graphql';

export const GET_ACTIVE_ORDER = gql`
    query GetActiveOrder {
        activeOrder {
            ...Cart
            
            shippingAddress{
                ...OrderAddress
            }
            billingAddress{
                ...OrderAddress
            }
            customFields{
                paymentSchedule
            }
        }
       
    }
    ${CART_FRAGMENT}
`;

export const placeorderService = {
    async getActiveOrder() {
        const client = createApolloClient();
        // caching logic
        const resCartProductsList = await client.query({
            query: GET_ACTIVE_ORDER,
        });
        if (resCartProductsList && resCartProductsList.data && resCartProductsList.data.activeOrder)
            return Promise.resolve(resCartProductsList.data?.activeOrder);
        else return Promise.resolve([]);
    },
}